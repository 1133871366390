import React from "react";
import FullPageScroll from "./FullPageScroll";

function App() {
  return (
      <FullPageScroll/>
  );
}

export default App;
